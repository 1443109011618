import {saveAs} from 'file-saver'
import XLSX from 'xlsx'
import CryptoJS from 'crypto-js/crypto-js'

/**
 * 登录时使用，前端密码加密传输
 * @param msg 密码
 * @param key 验证码ID
 * @returns {string}
 */
export function encrypt(msg, key) {
  key = CryptoJS.MD5(key).toString()
  var encrypted = CryptoJS.AES.encrypt(msg, key)
  return encrypted.toString()
}

function s2ab(s) {
  if (typeof ArrayBuffer !== 'undefined') {
    const buf = new ArrayBuffer(s.length)
    var view = new Uint8Array(buf)
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF
    return buf
  } else {
    const buf = new Array(s.length)
    for (let i = 0; i !== s.length; ++i) buf[i] = s.charCodeAt(i) & 0xFF
    return buf
  }
}

export function export_table(tab) {
  var wb = XLSX.utils.table_to_book(document.querySelector(tab))
  var wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: true, type: 'array'})
  try {
    saveAs(new Blob([wbout], {type: 'application/octet-stream'}), new Date().getTime() + '.xlsx')
  } catch (e) {
    if (typeof console !== undefined) {
      console.log(e, wbout)
    }
  }
  return wbout
}

/**
 * 导出json数据
 * @param {Array} data json数组
 * @param {Object} header 主表头
 * @param {Object} itemHeader 子表头
 * 注：如有子表，限定子表对应键名为item
 */
export function export_json(filename, data, header, itemHeader) {
  const h1 = Object.keys(header)
  const l1 = h1.length
  var ws = XLSX.utils.json_to_sheet([header], {skipHeader: true, header: h1})
  var r = {}
  var n = 1
  if (itemHeader !== undefined) {
    const h2 = Object.keys(itemHeader)
    XLSX.utils.sheet_add_json(ws, [itemHeader], {skipHeader: true, header: h2, origin: {r: 0, c: l1}})
    data.forEach(item => {
      for (let i = 0; i < l1; i++) {
        r[h1[i]] = item[h1[i]]
      }
      XLSX.utils.sheet_add_json(ws, [r], {skipHeader: true, header: h1, origin: -1})
      XLSX.utils.sheet_add_json(ws, item.item, {skipHeader: true, header: h2, origin: {r: n, c: l1}})
      n += item.item.length
    })
  } else {
    XLSX.utils.sheet_add_json(ws, data, {skipHeader: true, header: h1, origin: 'A2'})
    // data.forEach(item => {
    //   for (let i = 0; i < l1; i++) {
    //     r[h1[i]] = item[h1[i]]
    //   }
    //   XLSX.utils.sheet_add_json(ws, [r], { skipHeader: true, header: h1, origin: -1 })
    // })
  }
  const wopts = {bookType: 'xlsx', bookSST: false, type: 'binary'}
  const wb = {SheetNames: ['Sheet1'], Sheets: {}, Props: {}}
  wb.Sheets['Sheet1'] = ws // XLSX.utils.json_to_sheet(data)
  saveAs(new Blob([s2ab(XLSX.write(wb, wopts))], {type: 'application/octet-stream'}), (filename !== undefined && filename !== null ? filename : new Date().getTime()) + '.xlsx')
}

export function hasSuffix(str, target) {
  const start = str.length - target.length
  const arr = str.substr(start, target.length)
  if (arr === target) {
    return true
  }
  return false
}

export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

export function formatTime(time, option) {
  time = time * 1000
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) { // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分'
  }
}

export function dateFormater(t, hms = false, onlyTime = false) {
  if (t !== 0) {
    const now = t < 1000000000000 ? new Date(t * 1000) : new Date(t)
    if (onlyTime) {
      let hour = now.getHours()
      let minute = now.getMinutes()
      let second = now.getSeconds()
      hour = ('' + hour).length === 1 ? '0' + hour : '' + hour
      minute = ('' + minute).length === 1 ? '0' + minute : '' + minute
      second = ('' + second).length === 1 ? '0' + second : '' + second
      return hour + ':' + minute + ':' + second
    } else {
      const year = now.getFullYear()
      let month = now.getMonth() + 1
      let date = now.getDate()
      month = ('' + month).length === 1 ? '0' + month : '' + month
      date = ('' + date).length === 1 ? '0' + date : '' + date
      if (hms) {
        let hour = now.getHours()
        let minute = now.getMinutes()
        let second = now.getSeconds()
        hour = ('' + hour).length === 1 ? '0' + hour : '' + hour
        minute = ('' + minute).length === 1 ? '0' + minute : '' + minute
        second = ('' + second).length === 1 ? '0' + second : '' + second
        return year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second
      }
      return year + '-' + month + '-' + date
    }
  }
}

const rolemap = {
  'sa': '超级管理员',
  'COMMON': '普通用户',
  'DEALER': '代理经销商'
}

export const roleFormatter = (role) => {
  return rolemap[role]
}

export const maintenanceStatusOptions = [
  {
    value: 1,
    label: '已完成'
  },
  {
    value: 2,
    label: '未完成'
  },
  {
    value: 3,
    label: '待处理',
    disabled: true
  }
]

export const maintenanceTypeOptions = [
  // {
  //   value: 1,
  //   label: '维修'
  // },
  {
    value: 2,
    label: '移机'
  },
  {
    value: 6,
    label: '维保'
  },
  {
    value: 3,
    label: '拆机'
  },
  {
    value: 9,
    label: '换机'
  },
  {
    value: 7,
    label: '超标巡查'
  },
  {
    value: 8,
    label: '异常离线巡查'
  },


]

export const maintenanceSubtypeOptions = [
  {
    value: 1,
    label: '风机设备'
  },
  {
    value: 2,
    label: '净化设备'
  },
  {
    value: 3,
    label: '检测仪设备'
  }
]

export const maintenanceTimeSpanOptions = [
  {
    label: '0',
    value: 0
  },
  {
    label: '1',
    value: 1
  },
  {
    label: '2',
    value: 2
  },
  {
    label: '3',
    value: 3
  },
  {
    label: '4',
    value: 4
  },
  {
    label: '5',
    value: 5
  },
  {
    label: '6',
    value: 6
  },
  {
    label: '7',
    value: 7
  },
  {
    label: '8',
    value: 8
  },
  {
    label: '9',
    value: 9
  },
  {
    label: '10',
    value: 10
  }
]

export const maintenanceAbnormalOptions = [
  {
    value: 1,
    label: '风机监控异常'
  },
  {
    value: 2,
    label: '净化器监控异常'
  },
  {
    value: 3,
    label: '油烟检测传感异常'
  },
  {
    value: 4,
    label: '油烟监测异常'
  },
  {
    value: 5,
    label: '监控异常'
  },
  {
    value: 6,
    label: '监测仪、风机监控异常'
  }
]

export const maintenanceDeviceFailureCauseOptions = [
  {
    value: '风机监控损坏',
    label: '风机监控损坏'
  },
  {
    value: '净化机监控损坏',
    label: '净化机监控损坏'
  },
  {
    value: '油烟检测传感损坏',
    label: '油烟检测传感损坏'
  },
  {
    value: '店家原因',
    label: '店家原因'
  },
  {
    value: '其他原因',
    label: '其他原因'
  }
]

export const maintenanceErrorDescOptions = [
  {
    label: '数据漂移',
    value: '数据漂移'
  },
  {
    label: '数据偏小',
    value: '数据偏小'
  },
  {
    label: '数据偏大',
    value: '数据偏大'
  },
  {
    label: '异常离线',
    value: '异常离线'
  },
  {
    label: '长时间为0',
    value: '长时间为0'
  }
]

export const maintenanceAbnormalCauseOptionsTypeTwo = [
  {
    value: '店家歇业',
    label: '店家歇业'
  },
  {
    value: '店家原因',
    label: '店家原因'
  },
  {
    value: '设备故障',
    label: '设备故障'
  }
]

export const maintenanceAbnormalCauseOptions = [
  {
    value: '数据正常',
    label: '数据正常'
  },
  {
    value: '店家原因',
    label: '店家原因'
  },
  {
    value: '设备原因',
    label: '设备原因'
  },
  {
    value: '其他原因',
    label: '其他原因'
  }
]

export function maintenanceTypeFormatter(r, c) {
  switch (r[c.property]) {
    case 1:
      return '维修'
    case 2:
      return '移机'
    case 3:
      return '拆机'
    case 6:
      return '维保'
    case 7:
      return '超标巡查'
    case 8:
      return '异常离线巡查'
    case 9:
      return '换机'
    default:
      return ''
  }
}

export function maintenanceStatusFormatter(r, c) {
  switch (r[c.property]) {
    case 1:
      return '已完成'
    case 2:
      return '未完成'
    case 3:
      return '待处理'
    default:
      return '未知状态'
  }
}

export const statusFormatter = (typ, status) => {
  switch (typ) {
    case 'YON': {
      switch (status) {
        case 1:
          return '<span class="el-tag el-tag--info">是</span>'
        case 0:
          return '<span class="el-tag el-tag--info">否</span>'
        default:
          return ''
      }
    }
    case 'ENABLE':
      switch (status) {
        case 1:
          return '<span class="el-tag el-tag--success">正常</span>'
        case 99:
          return '<span class="el-tag el-tag--info">禁用</span>'
        default:
          return ''
      }
    case 'AREA':
      switch (status) {
        case 1:
          return '<span class="el-tag el-tag--info">省级</span>'
        case 2:
          return '<span class="el-tag el-tag--info">市级</span>'
        case 3:
          return '<span class="el-tag el-tag--info">区县</span>'
        case 4:
          return '<span class="el-tag el-tag--info">街道</span>'
        default:
          return ''
      }
  }
}

export const referToMap = (data, k = 'K', v = 'V') => {
  const referMap = {}
  data.forEach(item => {
    referMap[item[k]] = item[v]
  })
  return referMap
}

export const distinctArray = (arr, key) => {
  var tmap = {}
  var result = []
  arr.forEach(function (e) {
    if (!tmap[e[key]]) {
      tmap[e[key]] = true
      result.push(e)
    }
  })
  return result
}

export const getUserInfo = () => {
  if (stg().getItem('role') === '') {
    throw 'Login failed, please login again'
  }
  return JSON.parse(stg().getItem('user'))
}

export const hasLogin = () => {
  return stg().getItem('role') !== ''
}

export const stg = () => {
  return sessionStorage || window.sessionStorage
}

export const removeToken = () => {
  stg().clear()
}

export const getRouter = (data) => {
  //传过来的这个res.Menu，用data接住后端传过来的这个Menu
  //遍历一下后端传过来的数据
  const tmp = {}
  for (let i = 0, len = data.length; i < len; i++) {
    data[i].path = data[i].Path
    data[i].name = data[i].Name
    data[i].component = () =>
      //使用动态导入语法从指定路径加载对应的.vue文件
      import(`@/view${data[i].Path}.vue`)
    delete (data[i], 'Name')
    delete (data[i], 'Path')
    tmp[data[i].Id + ''] = data[i]
  }
  let arr
  const router = []
  data.forEach(item => {
    if (item.Pid !== '') {//表示有父级路由
      arr = item.Pid.substring(1, item.Pid.length - 1).split('/')//根据/分割路由
      const pid = arr[arr.length - 1]//取最后一个元素作为父级路由
      if (tmp[pid]) {//检查 tmp 对象中是否存在与 pid 匹配的键。若存在，说明找到了父级路由对象
        if (tmp[pid].children === undefined) {//检查父级路由是否有子路由，若没有，则创建一个空的子路由数组
          tmp[pid].children = []
        }
        tmp[pid].children.push(item)//将当前路由对象添加到父级路由的子路由数组中
      }
    } else {
      router.push(item)//将当前路由对象添加到根路由数组中
    }
  })
 //console.log(router)
  return router
}

export const jsonValidator = str => {
  try {
    JSON.parse(str)
    return true
  } catch (err) {
    return false
  }
}

export const getPname = (data, pid, label = 'Name') => {
  if (data && pid && pid !== '') {
    const name = []
    const ids = toIntArray(pid)
    let items = data
    for (let i = 0, len = ids.length; i < len; i++) {
      for (let j = 0; j < items.length; j++) {
        if (ids[i] === items[j]['Id']) {
          name.push(items[j][label])
          if (i === len - 1) {
            return name
          }
          if (items[j]['children']) {
            items = items[j]['children']
            j = 0
          }
          continue
        }
      }
    }
    return name
  }
  return []
}

export const getSinglePname = (data, pid, label = 'Name') => {
  if (pid && pid !== '') {
    const ids = toIntArray(pid)
    let items = data
    const len1 = ids.length
    let len2 = items.length
    for (let i = 0; i < len1; i++) {
      for (let j = 0; j < len2; j++) {
        if (ids[i] === items[j]['Id']) {
          if (i === len1 - 1) {
            return items[j][label]
          }
          items = items[j]['children']
          len2 = items.length
          j = 0
          continue
        }
      }
    }
  }
  return ''
}

export const getSPname = (data, ids, label = 'Name') => {
  if (ids.length !== 0) {
    let items = data
    for (let i = 0, len = ids.length; i < len; i++) {
      for (let j = 0; j < items.length; j++) {
        if (ids[i] === items[j]['Id']) {
          if (i === len - 1) {
            return items[j][label]
          }
          items = items[j]['children']
          j = 0
          continue
        }
      }
    }
  }
  return ''
}

export const getYearMonth = (n) => {
  const date = []
  const month = [
    {Id: '01', Name: '一月'},
    {Id: '02', Name: '二月'},
    {Id: '03', Name: '三月'},
    {Id: '04', Name: '四月'},
    {Id: '05', Name: '五月'},
    {Id: '06', Name: '六月'},
    {Id: '07', Name: '七月'},
    {Id: '08', Name: '八月'},
    {Id: '09', Name: '九月'},
    {Id: '10', Name: '十月'},
    {Id: '11', Name: '十一月'},
    {Id: '12', Name: '十一月'}
  ]
  const now = new Date()
  // const year = now.getFullYear() - n
  // for (let i = 0; i < 2 * n + 1; i++) {
  //   date.push({ Id: year + i + '', Name: year + i + '年', children: month })
  // }
  const year = now.getFullYear() - n
  for (let i = 0; i < n + 1; i++) {
    date.push({Id: year + i + '', Name: year + i + '年', children: month})
  }
  return date
}

export const underline = (str) => {
  if (str.length > 2) {
    return str.replace(/\B([A-Z])/g, '_$1').toLowerCase()
  }
  return str
}

export const fanFilterFormatter = (v) => {
  switch (v) {
    case '1':
      return '开'
    case '0':
      return '关'
    case '2':
      return '未连接'
    default:
      return v
  }
}

export const toIntArray = (str) => {
  if (typeof str === 'string') {
    return str.substring(1, str.length - 1).split('/').map((data) => {
      return +data
    })
  }
}

export const ifNull = (str, sep = '/') => {
  if (str && str !== '') {
    return str
  }
  return sep
}

export const ownerFilter = (owner, userInfo = null) => {
  if (owner === "")
    return ""
  if (userInfo === null || userInfo.pid == 0) {
    return toIntArray(owner)
  } else {
    var ownerData = owner.replace(userInfo.pid, "/")
    return toIntArray(ownerData)
  }
}

export const ownerJoin = (ownerArr, userInfo = null) => {
  if (ownerArr === undefined)
    return undefined
  if (userInfo === null ) {
    return `/${ownerArr.join('/')}/`
  } else {
    return `${userInfo.pid ? userInfo.pid : '/'}${ownerArr.join('/')}/`
  }
}

export const join = (arr, sep = '/') => {
  if (arr.length === 0) {
    return ''
  }
  return sep + arr.join(sep) + sep
}

export const treeFilter = (data, id = 'Id', pid = 'Pid') => {
  const tree = []
  if (data) {
    const tmp = {}
    for (let i = 0, len = data.length; i < len; i++) {
      tmp[`${data[i][id]}`] = data[i]
      // tmp[data[i].Id].children = []
    }
    let arr
    data.forEach(item => {
      if (item[pid] !== '') {
        arr = item[pid].substring(1, item[pid].length - 1).split('/')
        const tid = arr[arr.length - 1]
        if (tmp[tid]) {
          if (tmp[tid].children === undefined) {
            tmp[tid].children = []
          }
          tmp[tid].children.push(item)
        } else {
          tree.push(item)
        }
      } else {
        tree.push(item)
      }
    })
  }
  return tree
}

// 专门用于设备类型
export const deviceTreeFilter = (data, id = 'Id', pid = 'Pid') => {
  const tree = []
  if (data) {
    const tmp = {}
    for (let i = 0, len = data.length; i < len; i++) {
      if (data[i].Pid === '0')
        tmp[`${data[i][id]}`] = data[i]
    }
    let arr
    data.forEach(item => {
      if (item[pid] !== '0') {
        const tid = item[pid]
        if (tmp[tid]) {
          if (tmp[tid].children === undefined) {
            tmp[tid].children = []
          }
          tmp[tid].children.push(item)
          // tmp[tid].disabled = false
        } else {
          tree.push(item)
        }
      } else {
        tree.push(item)
      }
    })
  }
  return tree
}

export const paramFilter = (data) => {
  const tmp = {}
  const tree = []
  data.forEach(item => {
    if (tmp[item.Kind] === undefined) {
      tmp[item.Kind] = []
    }
    tmp[item.Kind].push({Id: item.V})
  })
  for (var key in tmp) {
    tree.push({Id: key, children: tmp[key]})
  }
  return tree
}

export const dataFormatter = (id, data, key = 'Id', label = 'Name') => {
  for (let i = 0, n = data.length; i < n; i++) {
    if (data[i][key] === id) {
      return data[i][label]
    }
  }
  return id
}

export const launchFullscreen = e => {
  if (document.fullscreenEnabled || document.webkitFullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled) {
    if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen()
        return
      }
      if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
        return
      }
      if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
        return
      }
      if (document.msExitFullscreen) {
        document.msExitFullscreen()
        return
      }
    } else {
      if (e.requestFullscreen) {
        e.requestFullscreen()
        return
      }
      if (e.webkitRequestFullscreen) {
        e.webkitRequestFullscreen()
        return
      }
      if (e.mozRequestFullScreen) {
        e.mozRequestFullScreen()
        return
      }
      if (e.msRequestFullscreen) {
        e.msRequestFullscreen()
        return
      }
    }
  } else {
    console.log('Fullscreen is not supported on your browser.')
  }
}

export const exitFullscreen = () => {
  switch (true) {
    case document.exitFullscreen:
      document.exitFullscreen()
      break
    case document.mozCancelFullScreen:
      document.mozCancelFullScreen()
      break
    case document.webkitExitFullscreen:
      document.webkitExitFullscreen()
      break
  }
}

export const Base64 = {
  enKey: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/',
  deKey: [
    -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
    -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
    -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 62, -1, -1, -1, 63,
    52, 53, 54, 55, 56, 57, 58, 59, 60, 61, -1, -1, -1, -1, -1, -1,
    -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
    15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, -1, -1, -1, -1, -1,
    -1, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, -1, -1, -1, -1, -1
  ],
  encode: function (src) {
    // 用一个数组来存放编码后的字符，效率比用字符串相加高很多。
    var str = []
    var ch1, ch2, ch3
    var pos = 0
    // 每三个字符进行编码。
    while (pos + 3 <= src.length) {
      ch1 = src.charCodeAt(pos++)
      ch2 = src.charCodeAt(pos++)
      ch3 = src.charCodeAt(pos++)
      str.push(this.enKey.charAt(ch1 >> 2), this.enKey.charAt(((ch1 << 4) + (ch2 >> 4)) & 0x3f))
      str.push(this.enKey.charAt(((ch2 << 2) + (ch3 >> 6)) & 0x3f), this.enKey.charAt(ch3 & 0x3f))
    }
    // 给剩下的字符进行编码。
    if (pos < src.length) {
      ch1 = src.charCodeAt(pos++)
      str.push(this.enKey.charAt(ch1 >> 2))
      if (pos < src.length) {
        ch2 = src.charCodeAt(pos)
        str.push(this.enKey.charAt(((ch1 << 4) + (ch2 >> 4)) & 0x3f))
        str.push(this.enKey.charAt(ch2 << 2 & 0x3f), '=')
      } else {
        str.push(this.enKey.charAt(ch1 << 4 & 0x3f), '==')
      }
    }
    // 组合各编码后的字符，连成一个字符串。
    return str.join('')
  },
  decode: function (src) {
    // 用一个数组来存放解码后的字符。
    var str = []
    var ch1, ch2, ch3, ch4
    var pos = 0
    // 过滤非法字符，并去掉'='。
    src = src.replace(/[^A-Za-z0-9\+\/]/g, '')
    // decode the source string in partition of per four characters.
    while (pos + 4 <= src.length) {
      ch1 = this.deKey[src.charCodeAt(pos++)]
      ch2 = this.deKey[src.charCodeAt(pos++)]
      ch3 = this.deKey[src.charCodeAt(pos++)]
      ch4 = this.deKey[src.charCodeAt(pos++)]
      str.push(String.fromCharCode(
        (ch1 << 2 & 0xff) + (ch2 >> 4), (ch2 << 4 & 0xff) + (ch3 >> 2), (ch3 << 6 & 0xff) + ch4))
    }
    // 给剩下的字符进行解码。
    if (pos + 1 < src.length) {
      ch1 = this.deKey[src.charCodeAt(pos++)]
      ch2 = this.deKey[src.charCodeAt(pos++)]
      if (pos < src.length) {
        ch3 = this.deKey[src.charCodeAt(pos)]
        str.push(String.fromCharCode((ch1 << 2 & 0xff) + (ch2 >> 4), (ch2 << 4 & 0xff) + (ch3 >> 2)))
      } else {
        str.push(String.fromCharCode((ch1 << 2 & 0xff) + (ch2 >> 4)))
      }
    }
    // 组合各解码后的字符，连成一个字符串。
    return str.join('')
  }
}


//这是数据重载
export function loadData(vm, func, typ) {
  switch (typ) {
    case 1:
      loadCustomer(vm, func)
      break
    case 2:
      vm.$get('admin/listBasicDataOfArea').then(res => {
        vm.$store.dispatch('setArea', res.Area)
        if (func) {
          func()
        }
      })
      break
    case 3:
      vm.$get('admin/listBasicDataOfUser').then(res => {
        vm.$store.dispatch('setUser', res.User)
        if (func) {
          func()
        }
      })
      break
    default: {
      var params = {
        code: vm.postdata && vm.postdata.AreaCode? vm.postdata.AreaCode:""
      }
      vm.$get('admin/listBasicDataOfArea', params).then(res => {
        vm.$store.dispatch('setArea', res.Area)
        vm.$message.success(`区域信息加载成功`)
        if (func) {
          func()
        }
      })

      loadCustomer(vm, vm.$message.success(`单位信息加载成功`))

      vm.$get('admin/listBasicDataOfLocale').then(res => {
        vm.$store.dispatch('setLocale', res.Locale)
        vm.$message.success(`监测点信息加载成功`)
        if (func) {
          func()
        }
      })

      vm.$get('admin/listBasicDataOfUser').then(res => {
        vm.$store.dispatch('setUser', res.User)
        vm.$message.success(`用户信息加载成功`)
        if (func) {
          func()
        }
      })
    }
  }
}

function loadCustomer(vm, func) {
  vm.$get('admin/listBasicDataOfCustomer').then(res => {
    const user = JSON.parse(stg().getItem('user'))
    const customerArray = Array.from(res.Customer)
    if (user.Info !== null) {
      let customer = {
        Address: user.Info.address,
        CreateAt: user.Info.create_at,
        Name: user.Info.name,
        Org: user.Info.org,
        Pid: user.Info.pid,
        Id: user.Info.id,
        Mobile: user.Info.mobile,
        Status: user.Info.status,
        Typ: user.Info.typ
      }
      customerArray.push(customer)
    }
    vm.$store.dispatch('setCustomer', customerArray)
    vm.$store.dispatch('setCustomerTree', treeFilter(customerArray))
  })
}

// 希尔排序
export function shellSort(arr) {
  const len = arr.length
  for (let gap = Math.floor(len / 2); gap > 0; gap = Math.floor(gap / 2)) {
    // 内层循环与插入排序的写法基本一致，只是每次移动的步长变为 gap
    for (let i = gap; i < len; i++) {
      let j = i
      const temp = arr[j]
      for (; j > 0; j -= gap) {
        if (temp <= arr[j - gap]) {
          break
        }
        arr[j] = arr[j - gap]
      }
      arr[j] = temp
    }
  }
  return arr
}

/**
 * @description 下载图片到本地
 */
export function downloadImage(imgsrc, name) {
  var image = new Image()
  // 解决跨域 Canvas 污染问题
  image.setAttribute('crossOrigin', 'anonymous')
  image.onload = function () {
    const canvas = document.createElement('canvas')
    canvas.width = image.width
    canvas.height = image.height
    const context = canvas.getContext('2d')
    context.drawImage(image, 0, 0, image.width, image.height)
    const url = canvas.toDataURL('image/png') // 得到图片的base64编码数据
    const a = document.createElement('a') // 生成一个a元素
    const event = new MouseEvent('click') // 创建一个单击事件
    a.download = name || 'photo' // 设置图片名称
    a.href = url // 将生成的URL设置为a.href属性
    a.dispatchEvent(event) // 触发a的单击事件
  }
  image.src = imgsrc
}

// base64转blob
export function base64ToBlob(code) {
  const parts = code.split(';base64,')
  const contentType = parts[0].split(':')[1]
  const raw = window.atob(parts[1])
  const rawLength = raw.length
  const uInt8Array = new Uint8Array(rawLength)
  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i)
  }
  return new Blob([uInt8Array], {type: contentType})
}

const x_pi = 3.14159265358979324 * 3000.0 / 180

// 百度地图坐标转换为高德地图坐标
export function bdToGdLatLng(lng, lat) {
  const x = lng - 0.0065
  const y = lat - 0.006
  const z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi)
  const theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi)
  return {
    lng: (z * Math.cos(theta)).toFixed(6),
    lat: (z * Math.sin(theta)).toFixed(6)
  }
}

//判断对象为空
export function isEmptyObj(obj) {
  for (let item in obj) {
    return false
  }
  return true
}
