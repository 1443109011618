import Vue from 'vue'
import { Tooltip, Container, Main, Header, Tabs, TabPane, InfiniteScroll, Steps, Image, Dropdown, DropdownMenu, DropdownItem, Alert, Step, Popover, Menu, MenuItem, Badge, Submenu, Transfer, ButtonGroup, RadioGroup, RadioButton, OptionGroup, Loading, Pagination, Tree, Dialog, Upload, Cascader, Select, Option, Radio, Input, InputNumber, CheckboxGroup, Checkbox, Notification, Message, MessageBox, Button, Card, Table, TableColumn, DatePicker, TimeSelect, Collapse, CollapseItem, Tag, FormItem, Form, Row, Col, Breadcrumb, BreadcrumbItem, Switch, Avatar } from 'element-ui'

Vue.use(Tooltip)
Vue.use(Container)
Vue.use(Main)
Vue.use(Header)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(InfiniteScroll)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Image)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Alert)
Vue.use(Popover)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Badge)
Vue.use(Submenu)
Vue.use(Transfer)
Vue.use(Loading)
Vue.use(Pagination)
Vue.use(Tree)
Vue.use(Dialog)
Vue.use(Upload)
Vue.use(Cascader)
Vue.use(Select)
Vue.use(Switch)
Vue.use(Option)
Vue.use(Radio)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(CheckboxGroup)
Vue.use(Checkbox)
Vue.use(Button)
Vue.use(Card)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(DatePicker)
Vue.use(TimeSelect)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Tag)
Vue.use(Row)
Vue.use(Col)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(ButtonGroup)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(OptionGroup)
Vue.use(Avatar)

Vue.prototype.$message = Message
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$notify = Notification
