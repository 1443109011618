import Vue from 'vue'
import NProgress from 'nprogress'
import './asset/css/normalize.css'
import './asset/css/element-ui.css'
import './asset/css/iconfont/iconfont.css'
import 'font-awesome/scss/font-awesome.scss'
import App from './App.vue'
import router from './router'
import store from './store'
import conf from './config'
import { stg, getRouter } from './util/index'
import ajax from './plugin/ajax'
import './plugin/element'
import urlGet from './plugin/urlGetParam'

import '../static/ueditor/ueditor.config.js'
import '../static/ueditor/ueditor.all.js'
import '../static/ueditor/lang/zh-cn/zh-cn.js'
import '../static/ueditor/ueditor.parse.js'
import '../static/ueditor/themes/default/css/ueditor.css'

import htmlToPdf from './util/htmlToPdf'
Vue.use(htmlToPdf)
// Vue.prototype.$utils = urlGet
// import api from './plugin/api'
import Element from 'element-ui'
Vue.use(Element)
Vue.prototype.$urlGet = urlGet
Vue.config.productionTip = false
Vue.use(ajax)

// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'

Vue.use(dataV)

// 引入阿里字体图标
import '@/asset/icon/iconfont.css'

// 动态添加路由
const r = stg().getItem('router')
if (r) {
  const routers = getRouter(JSON.parse(r))
  router.options.routes.push(...routers)
  router.addRoutes(getRouter(routers))
}

// 路由拦截器
router.beforeEach((to, from, next) => {
  NProgress.start()
  if (stg().getItem('token')) {
    next()
  } else {
    if (conf.whiteList.includes(to.path)) {
      next()
    } else {
      next(`/login`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done() // 结束Progress
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
