export default {
  // baseApi: 'http://localhost:8080',
  // /api
  // baseApi: 'http://hzmingrui.cn',
  // baseApi: 'http://admin.hzmingrui.cn', // 正式环境 明瑞、成都
  // baseApi: 'http://116.62.234.187:8088', // 正式环境(后端在测试服务器)
  baseApi: 'http://116.62.234.187:8120', //
  // baseApi: 'http://116.62.234.187:8080', // 测试服务器
  // baseApi: 'http://admin_mr.test.zhuyitech.com:8888', // 测试环境
  appName: '餐饮油烟管理智慧平台',
  whiteList: ['/login', '/Notification', '/SpecificLogin'],
  uploadApi: 'http://116.62.234.187:8088'
}
