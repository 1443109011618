import Vue from 'vue'
import Vuex from 'vuex'
import {treeFilter, stg, removeToken, getRouter} from '@/util/index'
import conf from '@/config'

Vue.use(Vuex)

const state = {
  props: {
    label: 'Name',
    key: 'Id',
    value: 'Id',
    expandTrigger: 'hover',
    children: 'children'
  },
  clientHeight: document.documentElement.clientHeight * 0.75,
  area: [],
  customer: [],
  locale: [],
  user: [],
  customerTree: [],
  areaTree: [],
  menu: [],
  shopInfo: stg().getItem('user') ? JSON.parse(stg().getItem('user')).Info : null, // 店铺信息
  showMoreDistrictInfo: false,
  districtInfoType: null,
  newBoardData: null,
  boardDataStack: [], // boardData堆栈，用于三级地图的返回功能
  topBoardData: {
    level: null
  },
  specificEntrancesList: {
    'wasu': {
      subname: 'wasu',
      appName: '华数',
      verif: {
        username: '径山镇',
        password: '123456',
        noCode: true
      },
      docTitle: null
    },
    'chengdu': {
      subname: 'chengdu',
      appName: '成都市餐饮油烟管理服务平台',
      verif: null,
      docTitle: '成都市餐饮油烟管理服务平台'
    },
    'unicom': {
      subname: 'unicom',
      appName: '中国联通餐饮油烟管理智慧平台',
      verif: null,
      docTitle: '中国联通餐饮油烟管理智慧平台'
    },
    'yannan': {
      subname: 'yannan',
      appName: '盐南高新区餐饮油烟管理智慧平台',
      verif: {
        username: '盐南高新区',
        password: 'vortex2021@',
        noCode: true
      },
      docTitle: '盐南高新区餐饮油烟管理智慧平台'
    },
    'adbl': {
      subname: 'adbl',
      appName: '埃德布鲁餐饮油烟管理智慧平台',
      verif: null,
      docTitle: '埃德布鲁餐饮油烟管理智慧平台'
    },
    'yannanlogin': {
      subname: 'yannan',
      appName: '盐南高新区餐饮油烟管理智慧平台',
      verif: null,
      docTitle: '盐南高新区餐饮油烟管理智慧平台'
    },
    'linping': {
      subname: 'linping',
      appName: '临平区餐饮油烟管理服务平台',
      verif: null,
      docTitle: '临平区餐饮油烟管理服务平台'
    },
    'chengdu_jinjiangqu': {
      subname: 'chengdu',
      appName: '成都市餐饮油烟管理服务平台',
      verif: {
        username: '成都市锦江区在线油烟监管',
        password: '4658JINj',
        noCode: true
      },
      docTitle: '成都市餐饮油烟管理服务平台'
    },
    'chengdu_gaoxinqu': {
      subname: 'chengdu',
      appName: '成都市餐饮油烟管理服务平台',
      verif: {
        username: '成都高新区在线油烟监管',
        password: 'Gxq74100',
        noCode: true
      },
      docTitle: '成都市餐饮油烟管理服务平台',
      des: '/dashboard'
    },
    'chengdu_xiaojiahe': {
      subname: 'chengdu',
      appName: '成都市餐饮油烟管理服务平台',
      verif: {
        username: '高新区肖家河街道在线油烟监管',
        password: 'XIAOjh506',
        noCode: true
      },
      docTitle: '成都市餐饮油烟管理服务平台',
      des: '/dashboard'
    },
    'chengdu_longquanyi': {
      subname: 'chengdu',
      appName: '成都市餐饮油烟管理服务平台',
      verif: {
        username: '成都市龙泉驿区在线油烟监管',
        password: 'CDlqy6523',
        noCode: true
      },
      docTitle: '成都市餐饮油烟管理服务平台',
      des: '/dashboard'
    },
    'chengdu_jianyang': {
      subname: 'chengdu',
      appName: '成都市餐饮油烟管理服务平台',
      verif: {
        username: '成都市简阳市在线油烟监管',
        password: '659JIANy',
        noCode: true
      },
      docTitle: '成都市餐饮油烟管理服务平台',
      des: '/dashboard'
    },
    'chengdu_xinjin': {
      subname: 'chengdu',
      appName: '成都市餐饮油烟管理服务平台',
      verif: {
        username: '新津区在线油烟监管',
        password: 'XIN525jin',
        noCode: true
      },
      docTitle: '成都市餐饮油烟管理服务平台',
    },
    'sccgj': {
      subname: 'sccgj',
      appName: '油烟管理服务平台',
      verif: {
        username: '遂昌城管局',
        password: '123456',
        noCode: true
      }
    },
    'zhxs': {
      subname: 'zhxs',
      appName: '珠海香山环保研究设计院',
      verif: {
        username: '珠海香山环保研究设计院',
        password: '123456',
        noCode: true
      },
      docTitle: '珠海香山环保研究设计院',
      des: '/login'
    },
    'chengdu_chongzhou': {
      subname: 'chengdu',
      appName: '崇州市餐饮油烟管理服务平台',
      verif: {
        username: '崇州市在线油烟监管',
        password: 'mrohR16461',
        noCode: true
      },
      docTitle: '崇州市餐饮油烟管理服务平台',
    },

  },
  specificEntrance: {
    subname: null,
    appName: conf.appName
  }
}

const mutations = {
  verifyUserWithoutLogin: (state, {that, subname}) => {
    const typ2Level = ['country', 'province', 'city', 'district','street']
    if (state.specificEntrancesList.hasOwnProperty(subname)) {
      const item = state.specificEntrancesList[subname]
      const des = item.des || '/NewBoard'
      state.specificEntrance = {...item}
      conf.appName = item.appName
      if (item.docTitle) {
        document.title = item.docTitle
      }
      if (item.verif) {
        if (
          state.specificEntrance.subname === 'wasu' ||
          state.specificEntrance.subname === 'chengdu' ||
          state.specificEntrance.subname === 'yannan'
        ) {
          that.$post('loginAction', item.verif)
            .then((res) => {
              removeToken()
              stg().setItem('token', res.Token)
              stg().setItem('role', res.Role)
              stg().setItem('router', JSON.stringify(res.Menu))
              stg().setItem('user', JSON.stringify(res.User))
              // stg().setItem('shopInfo', JSON.stringify(res.User))
              // console.log(res.User, '=================================')
              state.shopInfo = res.User.Info
              const routers = getRouter(res.Menu)
              that.$router.options.routes = []
              that.$router.options.routes.push(...routers)
              that.$router.addRoutes(routers)
            })
            .then(() => {
              that.$post('admin/queryBoardDataBaseArea').then((res) => {
                // console.log('Login respose!!!!!',res)
                const menus = JSON.parse(stg().getItem('router'))
                if (
                  menus.findIndex((item) => {
                    return item.Path === '/NewBoard'
                  }) >= 0
                ) {
                  if (typeof res === 'object' && res !== null) {
                    if (res.Typ <= 3) {
                      stg().setItem('areaCode', res.Code)
                      stg().setItem('quCode', res.Code)
                      stg().setItem('type', 1)
                      stg().setItem('level', typ2Level[res.Typ])
                      that.$router.push({
                        path: des
                      })
                    } else {
                      stg().setItem('areaCode', res.Code)
                      stg().setItem('quCode', res.Code)
                      stg().setItem('type', 0)
                      stg().setItem('level', 'street')
                      if (res.Polyline) { // 判断是否有polyline值，若有则跳转
                        stg().setItem('quPolyline', res.Polyline)
                      } else {
                        stg().setItem('quPolyline', '')
                      }
                      that.$router.push({
                        path: des
                      })
                    }
                  } else {
                    stg().setItem('areaCode', '100000')
                    stg().setItem('quCode', '0')
                    stg().setItem('type', 1)
                    stg().setItem('level', 'country')
                    that.$router.push({
                      path: des
                    })
                  }
                } else {
                  that.$router.push({path: '/'})
                }
              })
            })
        } else {
          that.$post('loginAction', item.verif)
            .then((res) => {
              removeToken()
              stg().setItem('token', res.Token)
              stg().setItem('role', res.Role)
              stg().setItem('router', JSON.stringify(res.Menu))
              stg().setItem('user', JSON.stringify(res.User))
              // stg().setItem('shopInfo', JSON.stringify(res.User))
              // console.log(res.User, '=================================')
              state.shopInfo = res.User.Info
              const routers = getRouter(res.Menu)
              that.$router.options.routes = []
              that.$router.options.routes.push(...routers)
              that.$router.addRoutes(routers)
            })
            .then(() => {
              that.$post('admin/queryBoardDataBaseArea').then((res) => {
                const menus = JSON.parse(stg().getItem('router'))
                if (
                  menus.findIndex((item) => {
                    return item.Path === '/NewBoard'
                  }) >= 0
                ) {
                  if (typeof res === 'object' && res !== null) {
                    if (res.Typ < 3) {
                      stg().setItem('areaCode', res.Code)
                      stg().setItem('quCode', res.Code)
                      stg().setItem('type', 1)
                      stg().setItem('level', typ2Level[res.Typ])
                      that.$router.push({
                        path: des
                      })
                    } else {
                      stg().setItem('areaCode', res.Code)
                      stg().setItem('quCode', res.Code)
                      stg().setItem('type', 0)
                      stg().setItem('level', 'district')
                      if (res.Polyline) {
                        stg().setItem('quPolyline', res.Polyline)
                      } else {
                        stg().setItem('quPolyline', '')
                      }
                      // if (this.loginForm.username == '盐南高新区') { stg().setItem('quName', '盐南高新区') } else stg().setItem('quName', '')
                      that.$router.push({
                        path: des
                      })
                    }
                  } else {
                    stg().setItem('areaCode', '100000')
                    stg().setItem('quCode', '0')
                    stg().setItem('type', 1)
                    stg().setItem('level', 'country')
                    that.$router.push({
                      path: des
                    })
                  }
                } else {
                  that.$router.push({path: '/'})
                }
              })
            })
        }
      } else {
        that.$router.push({
          path: '/login', query: {subname}
        })
      }
    }
  },
  setSpecificEntrance: (state, data) => {
    state.specificEntrance = {...data}
  },
  setTopBoardData: (state, data) => {
    state.topBoardData = data
  },
  pushBoardDataStack: (state, data) => {
    state.boardDataStack.push(JSON.parse(JSON.stringify(data)))
  },
  popBoardDataStack: (state) => {
    state.boardDataStack.pop()
  },
  clearBoardDataStack: (state) =>{
    state.boardDataStack = []
  },
  setNewBoardDataChengdu: (state, data) => {
    const dataObj = data.dataObj
    const res = data.res
    if (res) {
      const exceedStandardYesterdays = (res.countyLevelData.exceedStandardYesterdays || []).map((v) => {
        v.type = '超标报警'
        return v
      })
      const filterAbnormallyUsedYesterdays = (res.countyLevelData.filterAbnormallyUsedYesterdays || []).map((v) => {
        v.type = '不正常使用净化器'
        return v
      })
      state.newBoardData = {
        ...dataObj,
        ...res,
        code: res.code,
        level: res.level,
        alarmData: [
          ...exceedStandardYesterdays,
          ...filterAbnormallyUsedYesterdays
        ]
      }
      if (res.level === 'district') {
        state.newBoardData.exceedStandardsByMonth = res.countyLevelData.exceedStandardsByMonth || []
        state.newBoardData.redEmissionsByMonth = res.countyLevelData.redEmissionsByMonth || []
      }
    } else {
      state.newBoardData = dataObj
    }
  },
  setNewBoardData: (state, data) => {
    const dataObj = data.dataObj
    const res = data.res
    if (res) {
      const exceedStandardYesterdays = (res.countyLevelData.exceedStandardYesterdays || []).map((v) => {
        v.type = '超标报警'
        return v
      })
      const filterAbnormallyUsedYesterdays = (res.countyLevelData.filterAbnormallyUsedYesterdays || []).map((v) => {
        v.type = '不正常使用净化器'
        return v
      })
      state.newBoardData = {
        ...dataObj,
        ...res,
        code: res.code,
        level: res.level,
        // 昨日在线率
        onlineRateBar: res.topList.online.map((item) => {
          res.onlineDevice.device.map((vo) => {
            if (item.K.split('/').join('') === vo.K.split('/').join('')) {
              item.V =
                vo.V == 0 ? 0 : ((item.V * 100) / vo.V).toFixed(2) * 1
              item.N = vo.N
            }
          })
          return item
        }).sort((o1, o2) => {
          if (o1.V < o2.V) {
            return 1
          } else if (o1.V > o2.V) {
            return -1
          } else {
            return 0
          }
        }),
        // 昨日达标率
        complianceRateBar: res.topList.compliance.map((item) => {
          res.onlineDevice.device.map((vo) => {
            if (item.K.split('/').join('') === vo.K.split('/').join('')) {
              item.V =
                vo.V == 0 ? 0 : ((item.V * 100) / vo.V).toFixed(2) * 1
              item.N = vo.N
            }
          })
          return item
        }).sort((o1, o2) => {
          if (o1.V < o2.V) {
            return 1
          } else if (o1.V > o2.V) {
            return -1
          } else {
            return 0
          }
        }),
        // 减排累计
        redEmissionsBar: res.topList.redEmissions.map((item) => {
          res.onlineDevice.device.map((vo) => {
            if (item.K.split('/').join('') === vo.K.split('/').join('')) {
              item.N = vo.N
            }
          })
          return item
        }),
        alarmData: [
          ...exceedStandardYesterdays,
          ...filterAbnormallyUsedYesterdays
        ]
      }
      if (res.level === 'district') {
        state.newBoardData.exceedStandardsByMonth = res.countyLevelData.exceedStandardsByMonth || []
        state.newBoardData.redEmissionsByMonth = res.countyLevelData.redEmissionsByMonth || []
      }
    } else {
      state.newBoardData = dataObj
    }
  },
  setShowMoreDistrictInfo: (state, data) => {
    state.showMoreDistrictInfo = data.isShow
    state.districtInfoType = data.type
  },
  setData: (state, data) => {
    state.area = data.Area
    state.customer = data.Customer
    state.locale = data.Locale
    state.user = data.User
    state.areaTree = treeFilter(state.area)
    state.customerTree = treeFilter(state.customer)
  },
  setMenu: (state, value) => {
    state.menu = value
  },
  clear: (state) => {
    state.area = null
    state.customer = null
    state.locale = null
    state.user = null
    state.areaTree = null
    state.customerTree = null
    state.shopInfo = null
  },
  setShopInfo: (state, data) => {
    state.shopInfo = data
  },
  setCustomer: (state, data) => {
    state.customer = data
  },
  setCustomerTree: (state, data) => {
    state.customerTree = data
  },
  clearCustomer: (state) => {
    state.customer = null
    state.customerTree = null
  },
  setArea: (state, data) => {
    state.area = data
    state.areaTree = treeFilter(data)
  },
  setLocale: (state, data) => {
    state.locale = data
  },
  setUser: (state, data) => {
    state.user = data
  },
}

const actions = {
  popNewBoardData: (context) => {
    return new Promise((resolve, reject) => {
      const length = state.boardDataStack.length
      if (length) {
        const t = state.boardDataStack[state.boardDataStack.length - 1]
        context.commit('setNewBoardData', {dataObj: t})
        context.commit('popBoardDataStack')
        resolve(t)
      } else {
        reject('Stack is null')
      }
    })
  },
  setData: ({commit}, data) => {
    commit('setData', data)
  },
  setCustomer: ({commit}, data) => {
    commit('setCustomer', data)
  },
  setCustomerTree: ({commit}, data) => {
    commit('setCustomerTree', data)
  },
  clearCustomer: ({commit}, data) => {
    commit('clearCustomer')
  },
  setArea: ({commit}, data) => {
    commit('setArea', data)
  },
  setLocale: ({commit}, data) => {
    commit('setLocale', data)
  },
  setUser: ({commit}, data) => {
    commit('setUser', data)
  },
  setMenu: ({commit}, menu) => {
    commit('setMenu', menu)
  },
  getShopInfo: ({commit}, data) => {
    commit('setShopInfo', data)
  }
}

// const getters = {
//   props: state => state.props,
//   area: state => state.area,
//   area: state => state.areaTree,
//   menu: state => state.menu
// }

export default new Vuex.Store({
  state,
  // getters,
  mutations,
  actions
})
