//路由
import Vue from 'vue'
import Router from 'vue-router'
// import Home from './view/Home.vue'

Vue.use(Router)


export default new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  linkActiveClass: 'is-active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: `/`,
      component: () => import('@/view/Home'),
      redirect: `/dashboard`,
      name: '首页',
      hidden: true,
      children: [{
        path: `/dashboard`,
        component: () => import('@/view/dashboard')
      }]
    },
    { path: `/Home`, hidden: true, redirect: `/dashboard` },
    { name: 'newboard', path: `/NewBoard`, component: () => import('@/view/NewBoard'), hidden: true },
    { name: 'SpecificLogin', path: `/SpecificLogin`, component: () => import('@/view/SpecificLogin'), hidden: true },
    {
      name: 'login',
      path: `/login`,
      component: () => import('@/view/login'),
      hidden: true,
      beforeEnter: (to, from, next) => {
        if (from.name === 'SpecificLogin') {
          next()
        } else {
          if (to.query.subname) {
            next({ path: '/SpecificLogin', query: to.query })
          } else {
            next()
          }
        }
      }
    },
    { path: `/404`, component: () => import('@/view/404'), hidden: true },
    { path: '*', redirect: `/404`, hidden: true },
    { path: '/Notification', component: () => import('@/view/bigscreen/components/datav/Chengdu/Notification'), hidden: true }
  ]
})
