import { stg } from '@/util/index'
import router from '@/router'
import conf from '@/config'
import axios from 'axios'
import qs from 'qs'
import { Message } from 'element-ui'

const reqList = []
const whiteList = [
  'admin/listDataDetectorDailySumDaysByLocaleId'
]

const stopRepeatRequest = function(reqList, url, cancel, errorMessage) {
  // 白名单
  if (whiteList.findIndex(item => item === url) === -1) return
  const errorMsg = errorMessage || ''
  for (let i = 0; i < reqList.length; i++) {
    if (reqList[i] === url) {
      cancel(errorMsg)
      return
    }
  }
  reqList.push(url)
}

const allowRequest = function(reqList, url) {
  for (let i = 0; i < reqList.length; i++) {
    if (reqList[i] === url) {
      reqList.splice(i, 1)
      break
    }
  }
}

// 创建axios实例
const service = axios.create({
  withCredentials: false,
  timeout: 150000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
})
// conf.baseApi =
// request拦截器
service.interceptors.request.use(config => {
  const token = stg().getItem('token')
  let cancel
  // 设置cancelToken对象
  config.cancelToken = new axios.CancelToken(function(c) {
    cancel = c
  })
  // 阻止重复请求。当上个请求未完成时，相同的请求不会进行
  stopRepeatRequest(reqList, config.url, cancel, `${config.url} 请求被中断`)
  if (token) {
    config.headers['Auth'] = token // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  return config
}, error => {
  // Do something with request error
  console.log(error) // for debug
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(response => {
  // 增加延迟，相同请求不得在短时间内重复发送
  setTimeout(() => {
    allowRequest(reqList, response.config.url)
  }, 1000)
  return response
})

export default {
  /**
   * Vue插件默认安装方法
   * @param {Object} Vue 当前vue实例对象
   * @param {Object} options 插件参数
   */
  install: (Vue, option) => {
    /**
     * @param {String} api 接口地址
     */
    Vue.prototype.$get = async(api, params) => {
      return new Promise((resolve, reject) => {
      service.get(`${(params && params.apiType === 'full') ? api : (conf.baseApi + '/' + api)}`, {
          params,
          paramsSerializer: function(params) {
            return qs.stringify(params, { arrayFormat: 'repeat', indices: false })
          }
        }).then(res => {
          if (res.status === 200 && res.data.Status === 200) {
            resolve(res.data.Data)
          } else if (res.status === 200 && res.data.Status > 200) {
            Message.success(res.data.Msg)
            resolve(true)
          } else if (res.status === 200 && res.data.Status === 114) {
            router.push({ path: `/login` })
          } else {
            if (res.data.Msg) {
              Message.error(res.data.Msg)
            }
            reject(res.data)
          }
        }).catch((e) => {
          reject(e)
        })
      })
    }
    Vue.prototype.$post = async(api, params, config) => {
      return new Promise((resolve, reject) => {
        service.post(`${conf.baseApi}/${api}`, params, { indices: false, ...config }).then(res => {
          if (res.status === 200 && res.data.Status === 200) {
            resolve(res.data.Data)
          } else if (res.status === 200 && res.data.Status > 200) {
            Message.success(res.data.Msg)
            resolve(true)
          } else if (res.status === 200 && res.data.Status === 114) {
            router.push({ path: `/login` })
          } else {
            if (res.data.Msg) {
              Message.error(res.data.Msg + '： ' + res.data.Data)
            }
            reject(res.data)
          }
        }).catch((e) => {
          reject(e)
        })
      })
    }
    Vue.prototype.$postNew = async(api, params, config) => {
      return new Promise((resolve, reject) => {
        service.post(`${conf.baseApi}/${api}`, params, { indices: false, ...config }).then(res => {
          if (res.status === 200 && res.data.Status === 200) {
            resolve(res.data)
          } else if (res.status === 200 && res.data.Status > 200) {
            Message.success(res.data.Msg)
            resolve(true)
          } else if (res.status === 200 && res.data.Status === 114) {
            router.push({ path: `/login` })
          } else {
            if (res.data.Msg) {
              Message.error(res.data.Msg + '： ' + res.data.Data)
            }
            reject(false)
          }
        }).catch((e) => {
          reject(e)
        })
      })
    }
    Vue.prototype.$download = async(api, params) => {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${conf.baseApi}/${api}`,
          data: params,
          responseType: 'blob',
          headers: {
            'Auth': stg().getItem('token')
          }
        }).then(res => {
          if (res.status === 200) {
            const blob = new Blob([res.data])
            var blobURL = window.URL.createObjectURL(blob)
            var tempLink = document.createElement('a')
            tempLink.style.display = 'none'
            tempLink.href = blobURL
            tempLink.setAttribute('download', decodeURI(res.headers['content-disposition'].replace('attachment;filename=', '')))
            if (typeof tempLink.download === undefined) {
              tempLink.setAttribute('target', '_blank')
            }
            document.body.appendChild(tempLink)
            tempLink.click()
            // window.URL.revokeObjectURL(blobURL)
            document.body.removeChild(tempLink)
            resolve()
          }
        }).catch((e) => {
          console.log(e)
          reject(e)
        })
      })
    }
  }
}
