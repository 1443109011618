<template>
  <div id="app">
    <transition name='fade' mode='out-in'>
      <router-view v-if="isReload"></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'app',
  provide() {
    return {
      reload: this.reload
    }
  },
  created() {
    // 在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
    }

    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener('beforeunload', () => {
      console.log('save store')
      sessionStorage.setItem('store', JSON.stringify({
        props: {
          label: 'Name',
          value: 'Id',
          expandTrigger: 'hover',
          children: 'children'
        },
        clientHeight: document.documentElement.clientHeight * 0.75,
        area: [],
        customer: [],
        locale: this.$store.state.locale,
        user: this.$store.state.user,
        customerTree: this.$store.state.customerTree,
        areaTree: this.$store.state.areaTree,
        menu: this.$store.state.menu,
        shopInfo: this.$store.state.shopInfo // 店铺信息
      }))
    })

    // ios废弃了beforeunload，使用pagehide代替
    window.addEventListener('pagehide', () => {
      sessionStorage.setItem('store', JSON.stringify({
        props: {
          label: 'Name',
          value: 'Id',
          expandTrigger: 'hover',
          children: 'children'
        },
        clientHeight: document.documentElement.clientHeight * 0.75,
        area: [],
        customer: [],
        locale: this.$store.state.locale,
        user: this.$store.state.user,
        customerTree: this.$store.state.customerTree,
        areaTree: this.$store.state.areaTree,
        menu: this.$store.state.menu,
        shopInfo: this.$store.state.shopInfo // 店铺信息
      }))
    })
  },
  data() {
    return {
      isReload: true
    }
  },
  methods: {
    reload() {
      this.isReload = false
      this.$nextTick(() => {
        this.isReload = true
      })
    }
  }
}
</script>
<style lang="scss">
.v-modal {
  z-index: 998 !important;
}
.el-table,
.el-table thead {
  color: #333333;
}
html,
body,
#app {
  width: 100%;
  height: 100%;
  font-size: 16px;
}
</style>
